@import 'configurable-values';

::-webkit-scrollbar {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 0.2rem;
  background-color: $nuetrals-grey-5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.2rem;
  background-color: $tint-primary-50;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
  display: none;
}
