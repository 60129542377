@import 'configurable-values';

mat-form-field.mat-form-field {
  .mat-input-element {
    color: $nuetrals-black-pure;
  }

  .mat-form-field-label {
    color: $nuetrals-black-pure;
    font-weight: bold;
  }

  .mat-form-field-underline {
    background-color: $tux;
  }

  .mat-form-field-ripple {
    background-color: $tux;
  }

  .mat-form-field-required-marker {
    color: $nuetrals-black-pure;
  }

  .mat-focused {
    .mat-form-field-label {
      color: $nuetrals-black-pure;
    }

    .mat-form-field-ripple {
      background-color: $tux;
    }

    .mat-form-field-required-marker {
      color: $nuetrals-black-pure;
    }

    .mat-input-element {
      color: $nuetrals-black-pure;
    }
  }

  .mat-form-field-invalid {
    .mat-input-element {
      color: $pastel-red;
    }

    .mat-form-field-label {
      color: $pastel-red;

      .mat-form-field-required-marker {
        color: $pastel-red;
      }
    }

    .mat-form-field-ripple {
      background-color: $pastel-red;
    }
  }
}

.lv-dispensary-search-container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;

  .lv-dispensary-search-box {
    width: 100%;
    padding-left: 8px;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 8px;
    border: solid 2px $nuetrals-grey-5;
    background-color: $nuetrals-white;

    mat-icon {
      color: $dark-grey;
    }

    input {
      width: 80%;
      height: 80%;
      border: none;
      outline: transparent;
      appearance: none;
      font-size: 14px;
      color: $dark-grey;
      line-height: 16px;
    }
  }
}

.lv-product-search-container {
  min-width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  //padding-right: 16px;

  .lv-product-search-box {
    width: 92%;
    padding-left: 8px;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 8px;
    border: solid 2px $nuetrals-grey-5;
    background-color: $nuetrals-white;

    mat-icon {
      color: $dark-grey;
    }

    input {
      width: 80%;
      height: 80%;
      border: none;
      outline: transparent;
      appearance: none;
      font-size: 14px;
      color: $dark-grey;
      line-height: 16px;
    }
  }
}
