@import '../global/configurable-values';
@import '../global/mixins';

.lv-offer-filter-row {
  .mat-menu {
    background: $nuetrals-white;
    color: $nuetrals-black-pure;
  }
}

.lv-filter-offers {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 14px 10px;
  color:  $nuetrals-black-pure;
  cursor: pointer;
  border: 2px solid  $nuetrals-black-pure;
  position: relative;

  .mat-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 33px;
    width: auto;
    height: auto;
    margin-right: 0 !important;
  }

  input[type='text'] {
    cursor: pointer;
    color: $nuetrals-black-pure;
    border: none;
    outline: none;
    font-family: AvenirNext, sans-serif;
    font-size: 19px;
    font-weight: 700;
    padding: 0;
    line-height: 26px;
    width: 172px;
    padding-left: 35px;
    &::placeholder {
      color: $nuetrals-black-pure;
      opacity: 1;
    }
  }  
}

// .lv-filter-offers-focus {
//   -webkit-border-radius: 30px;
//   -moz-border-radius: 30px;
//   border-radius: 30px;
//   padding: 14px 10px;
//   color:  $nuetrals-black-pure;
//   cursor: pointer;
//   border: 2px solid  $nuetrals-black-pure;
//   position: relative;

//   .mat-icon {
//     width: 25px;
//   }

//   input[type='text'] {
//     cursor: auto;
//     background: $nuetrals-white;
//     color: $tux;
//     border: none;
//     outline: none;
//     font-size: 17px;
//   }

//   ::placeholder {
//     /* Chrome, Firefox, Opera, Safari 10.1+ */
//     color: $tux;
//     opacity: 1; /* Firefox */
//   }

//   ::-ms-input-placeholder {
//     /* Microsoft Edge */
//     color: $tux;
//   }
// }

.lv-filter-title {
  color: $nuetrals-black-pure;
  min-width: 250px;
}

.mat-menu-panel {
  -ms-overflow-style: none !important; /* IE and Edge */
  width: 260px;
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.lv-nested-menu {
  background: $nuetrals-white !important;
  color: $tux !important;
  padding-right: 5px !important;

  .mat-menu-item {
    background: transparent;
    color: $tux !important;
  }

  .mat-menu-item-submenu-trigger::after {
    background: transparent;
    color: $tux !important;
  }

  form {
    color: $tux !important;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
}
