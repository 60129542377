@import '../global/configurable-values';
@import '../global/mixins';

.team-review-nav-container {
  width: 100%;
  // padding-top: 35px;
  background-color: $nuetrals-white;

  // @include xs {
  //   padding-left: 10px;
  //   margin: 0;
  // }
  // @include sm {
  //   padding-left: 50px;
  //   margin: 0;
  // }

  // @include md {
  //   padding-left: 100px;
  //   margin: 0;
  // }

  // @include lg {
  //   padding-left: 150px;
  //   margin: 0;
  // }

  // @include xl {
  //   padding-left: 250px;
  //   margin: 0;
  // }
}

.team-review-nav-logo {
  width: 56px;
  height: 56px;
  margin: 0 8px 16px 0;
  object-fit: contain;
}

.team-review-close-icon {
  width: 56px;
  height: 56px;
}

.team-review-nav-column {
  width: 100%;
  max-width: 1025px;
}

.team-review-nav-title {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.team-review-nav-description {
  margin: 7px;
  font-family: AvenirNext, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.team-review-edit-container {
  width: 100%;
  margin: 0;
  padding-top: 50px;
  background-color: $nuetrals-white;
}

.team-review-edit-form {
  .mat-form-field {
    width: 100%;
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      padding: 1em 0;
    }
  }

  // @include xs {
  //   padding-left: 10px;
  //   margin: 0;
  // }
  // @include sm {
  //   padding-left: 25px;
  //   margin: 0;
  // }

  // @include md {
  //   padding-left: 75px;
  //   margin: 0;
  // }

  // @include lg {
  //   padding-left: 200px;
  //   margin: 0;
  // }

  // @include xl {
  //   padding-left: 256px;
  //   margin: 0;
  // }
}

.team-review-badge-container {
  img {
    height: 100%;
    width: 100%;
    max-height: 345px;
    cursor: pointer;
  }
}

.team-review-badge-dialog {
  text-align: center;
  img {
    height: auto;
    width: 100%;
    max-width: 550px;
    max-height: 400px;
  }
}

.team-review-actions {
  width: 100%;
  margin-bottom: 50px;
  background-color: $nuetrals-white;
}

.team-action-buttons {
  padding-right: 50px;
}
