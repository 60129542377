@import '../global/configurable-values';
@import '../global/_mixins';

.lv-dispensary-grid {
  margin: 20px auto;
  width: 100%;
}

.lv-dispensary-leaderboard {
  @include xs {
    min-height: 225px;
    padding: 10px;
  }

  @include sm {
    padding-left: 25px;
    padding-right: 25px;
    min-height: 125px;
  }

  @include md {
    padding-left: 75px;
    padding-right: 75px;
    min-height: 150px;
  }

  @include lg {
    padding-left: 200px;
    padding-right: 200px;
    min-height: 175px;
  }

  @include xl {
    padding-left: 256px;
    padding-right: 256px;
    min-height: 200px;
  }
}

.lv-dispensary-tile {
  border-radius: 8px;
  background-color: $background-tux-darkest;
  text-align: left;
  cursor: pointer;
}

.lv-dispensary-container {
  padding: 16px 19px 18px 16px;
  width: 100%;
}

.lv-dispensary-left {
  width: 75%;
  text-align: left;
}

.lv-dispensary-right {
  width: 25%;
  text-align: center;

  .lv-dispensary-employee {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.31;
  }

  .lv-dispensary-count {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.29;
    color: $nuetrals-white;
  }
}

.lv-dispensary-tile-name {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: $nuetrals-white;
}

.lv-dispensary-tile-address {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: $nuetrals-grey-4;
}
